import React, { useState } from 'react';
import { Card, CardContent } from './components/Card';
import { Input } from './components/Input';
import { Button } from './components/Button';
import './WeatherApp.css';

const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;

const WeatherApp = () => {
  const [location, setLocation] = useState('');
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [useImperial, setUseImperial] = useState(false);

  const fetchWeather = async () => {
    setLoading(true);
    setError(null);
    try {
      let url;
      if (/^\d{5}(-\d{4})?$/.test(location)) {
        // If input is a ZIP code
        url = `https://api.openweathermap.org/data/2.5/weather?zip=${location},US&appid=${API_KEY}&units=metric`;
      } else {
        // If input is a city name
        url = `https://api.openweathermap.org/data/2.5/weather?q=${location}&appid=${API_KEY}&units=metric`;
      }

      const currentResponse = await fetch(url);
      const currentData = await currentResponse.json();

      if (currentData.cod !== 200) {
        throw new Error(currentData.message);
      }

      const airQualityResponse = await fetch(`https://api.openweathermap.org/data/2.5/air_pollution?lat=${currentData.coord.lat}&lon=${currentData.coord.lon}&appid=${API_KEY}`);
      const airQualityData = await airQualityResponse.json();

      const uvResponse = await fetch(`https://api.openweathermap.org/data/2.5/uvi?lat=${currentData.coord.lat}&lon=${currentData.coord.lon}&appid=${API_KEY}`);
      const uvData = await uvResponse.json();

      const forecastResponse = await fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${currentData.coord.lat}&lon=${currentData.coord.lon}&appid=${API_KEY}&units=metric`);
      const forecastData = await forecastResponse.json();

      setWeather({
        city: currentData.name,
        country: currentData.sys.country,
        current: {
          temperature: currentData.main.temp,
          feelsLike: currentData.main.feels_like,
          tempMax: currentData.main.temp_max,
          tempMin: currentData.main.temp_min,
          description: currentData.weather[0].description,
          windSpeed: currentData.wind.speed,
          windDirection: currentData.wind.deg,
          humidity: currentData.main.humidity,
        },
        airQuality: {
          pm25: airQualityData.list[0].components.pm2_5,
        },
        uv: uvData.value,
        forecast: forecastData.list.filter((item, index) => index % 8 === 0).slice(0, 5).map(item => ({
          date: new Date(item.dt * 1000),
          description: item.weather[0].description,
          tempMax: item.main.temp_max,
          tempMin: item.main.temp_min,
          pop: item.pop,
        })),
      });
    } catch (err) {
      setError('Error fetching weather data. Please ensure you entered a valid city name or ZIP code.');
    } finally {
      setLoading(false);
    }
  };

  const getWeatherIcon = (description) => {
    // This is a simple mapping, you might want to expand this based on OpenWeatherMap's icon codes
    const iconMap = {
      // Clear
    'clear sky': '☀️',
    'sunny': '☀️',

    // Clouds
    'few clouds': '🌤️',
    'scattered clouds': '⛅',
    'broken clouds': '☁️',
    'overcast clouds': '☁️',

    // Rain
    'light rain': '🌦️',
    'moderate rain': '🌧️',
    'heavy intensity rain': '🌧️',
    'very heavy rain': '🌧️',
    'extreme rain': '🌧️',
    'freezing rain': '🌨️',
    'light intensity shower rain': '🌦️',
    'shower rain': '🌧️',
    'heavy intensity shower rain': '🌧️',
    'ragged shower rain': '🌧️',

    // Drizzle
    'light intensity drizzle': '🌦️',
    'drizzle': '🌦️',
    'heavy intensity drizzle': '🌧️',
    'light intensity drizzle rain': '🌦️',
    'drizzle rain': '🌧️',
    'heavy intensity drizzle rain': '🌧️',
    'shower rain and drizzle': '🌧️',
    'heavy shower rain and drizzle': '🌧️',
    'shower drizzle': '🌦️',

    // Thunderstorm
    'thunderstorm with light rain': '⛈️',
    'thunderstorm with rain': '⛈️',
    'thunderstorm with heavy rain': '⛈️',
    'light thunderstorm': '🌩️',
    'thunderstorm': '⛈️',
    'heavy thunderstorm': '⛈️',
    'ragged thunderstorm': '⛈️',
    'thunderstorm with light drizzle': '⛈️',
    'thunderstorm with drizzle': '⛈️',
    'thunderstorm with heavy drizzle': '⛈️',

    // Snow
    'light snow': '🌨️',
    'snow': '❄️',
    'heavy snow': '❄️',
    'sleet': '🌨️',
    'light shower sleet': '🌨️',
    'shower sleet': '🌨️',
    'light rain and snow': '🌨️',
    'rain and snow': '🌨️',
    'light shower snow': '🌨️',
    'shower snow': '❄️',
    'heavy shower snow': '❄️',

    // Atmosphere
    'mist': '🌫️',
    'smoke': '🌫️',
    'haze': '🌫️',
    'sand/dust whirls': '🌪️',
    'fog': '🌫️',
    'sand': '🌫️',
    'dust': '🌫️',
    'volcanic ash': '🌋',
    'squalls': '🌬️',
    'tornado': '🌪️',

    // Additional
    'windy': '🌬️',
    'hail': '🌨️',
    };
    return iconMap[description.toLowerCase()] || '🌡️';
  };

  const convertTemp = (temp) => {
    return useImperial ? (temp * 9 / 5) + 32 : temp;
  };

  const convertSpeed = (speed) => {
    const kmhSpeed = speed * 3.6; // Convert m/s to km/h
    return useImperial ? kmhSpeed / 1.609344 : kmhSpeed; // Convert km/h to mph if imperial
  };

  const getWindDirection = (degree) => {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    return directions[Math.round(degree / 45) % 8];
  };

  const getDayOfWeek = (date) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days[date.getDay()];
  };

  const formatDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const getUnitSuffix = (type) => {
    switch (type) {
      case 'temperature':
        return useImperial ? '°F' : '°C';
      case 'speed':
        return useImperial ? 'mph' : 'km/h';
      default:
        return '';
    }
  };

  const formatPrecipitation = (pop) => {
    const percentage = pop * 100;
    return percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1);
  };

  return (
    <div className="weather-app">
      <Card className="main-card">
        <CardContent className="app-container">
          <h1 className="app-title">Weather App</h1>
          <div className="search-bar">
            <Input
              type="text"
              placeholder="Enter city name or ZIP code"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <Button onClick={fetchWeather} disabled={loading}>
              Search
            </Button>
          </div>
          <div className="units-toggle">
            <label>
              <input
                type="checkbox"
                checked={useImperial}
                onChange={() => setUseImperial(!useImperial)}
              />
              Use Imperial Units
            </label>
          </div>
          {loading && <p className="loading">Loading...</p>}
          {error && <p className="error">{error}</p>}

          {weather && (
            <div className="weather-content">
              <div className="current-weather">
                <h2>{weather.city}, {weather.country}</h2>
                <div className="temperature-display">
                  <span className="temperature">{Math.round(convertTemp(weather.current.temperature))}</span>
                  <span className="unit">{getUnitSuffix('temperature')}</span>
                </div>
                <p className="description">{getWeatherIcon(weather.current.description)} {weather.current.description}</p>
                <div className="details">
                  <p>Feels like: {Math.round(convertTemp(weather.current.feelsLike))}{getUnitSuffix('temperature')}</p>
                  <p>High: {Math.round(convertTemp(weather.current.tempMax))}{getUnitSuffix('temperature')} • Low: {Math.round(convertTemp(weather.current.tempMin))}{getUnitSuffix('temperature')}</p>
                  <p>Wind: {Math.round(convertSpeed(weather.current.windSpeed))}{getUnitSuffix('speed')} {getWindDirection(weather.current.windDirection)}</p>
                  <p>Humidity: {weather.current.humidity}%</p>
                  <p>PM2.5: {weather.airQuality.pm25.toFixed(0)} μg/m³ • UV Index: {weather.uv.toFixed(0)}</p>
                </div>
              </div>

              <div className="forecast">
                <h3>5-Day Forecast</h3>
                <div className="forecast-list">
                  {weather.forecast.map((day, index) => (
                    <div key={index} className="forecast-item">
                      <span className="day">{getDayOfWeek(day.date)}<span className="date"> - {formatDate(day.date)}</span></span>
                      <span className="rain-chance">Rain: {formatPrecipitation(day.pop)}%</span>
                      <span className="forecast-icon">{getWeatherIcon(day.description)}</span>
                      <span className="temp-range">
                        {Math.round(convertTemp(day.tempMax))}/{Math.round(convertTemp(day.tempMin))}
                        {getUnitSuffix('temperature')}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default WeatherApp;